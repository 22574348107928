import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout';
import BackgroundImage from '../../components/BackgroundImage';
import Image from '../../components/Image';
import { ImageOverlay } from '../../utils';
import { HTMLContent } from '../../components/Content';
import LinkComponent from '../../components/LinkComponent';
import useSiteMetadata from '../../components/SiteMetadata';
import MetaComponent from '../../components/MetaComponent';

export const AllNisekoPassPageTemplate = ({
  content,
  helmet,
  title,
  subTitle,
  image,
  tilesObject,
  infoTiles,
}) => {
  const mapTiles = () =>
    tilesObject.tiles.map((tile) => (
      <div className='service-inclusions-tile-wrapper column is-4'>
        <Image
          imageInfo={{ image: tile.image }}
          classes=''
          animationClass='hover-zoom'
        />
        <h2 className='anp-tile-title'>{tile.title}</h2>
        <div>{tile.text}</div>
      </div>
    ));
  const mapInfoTiles = () =>
    infoTiles.map((tile) => (
      <div className='service-inclusions-tile-wrapper column is-6'>
        <Image
          imageInfo={{ image: tile.image }}
          classes=''
          animationClass='hover-zoom'
        />
        <h2>{tile.title}</h2>
        <div>{tile.text}</div>
        <LinkComponent linkObject={tile.linkObject}>
          {tile.linkObject.title}
        </LinkComponent>
      </div>
    ));
  return (
    <div>
      {helmet || ''}
      <BackgroundImage classes={'page-header-image'} imageInfo={image.image}>
        <h1 className='title page-title'>
          <hr />
          {title}
        </h1>
        <ImageOverlay overlay={image.overlay} />
      </BackgroundImage>

      <div className='page-container container content'>
        <div className='all-niseko-title-section'>
          <div className='all-niseko-title'>{subTitle}</div>
          <HTMLContent content={content} />
        </div>

        <div>
          <div className='all-niseko-title'>{tilesObject.title}</div>
          <div className='columns all-niseko-tiles'>{mapTiles()}</div>
        </div>

        <LinkComponent
          linkObject={tilesObject.linkObject}
          className='all-niseko-button btn btn-main'>
          {tilesObject.linkObject.title}
        </LinkComponent>

        <div className='columns info-tiles-container'>{mapInfoTiles()}</div>
      </div>
    </div>
  );
};

const AllNisekoPassPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;
  const { title, siteUrl } = useSiteMetadata();
  return (
    <Layout>
      <AllNisekoPassPageTemplate
        content={frontmatter.content}
        location={location}
        title={frontmatter.title}
        subTitle={frontmatter.subTitle}
        image={frontmatter.heroImageObject}
        tilesObject={frontmatter.sections.tilesObject}
        infoTiles={frontmatter.sections.infoTiles}
        helmet={
          <MetaComponent
            title={`${frontmatter.seoObject.metaTitle}`}
            canonical={`${siteUrl}${location.pathname}`}
            siteName={`${title}`}
            description={`${frontmatter.seoObject.metaDescription}`}
            link={`${siteUrl}${location.pathname}`}
            image={`${frontmatter.heroImageObject.image}`}
          />
        }
      />
    </Layout>
  );
};

export default AllNisekoPassPage;

export const pageQuery = graphql`
  query allNisekoPassPageQueryZHHANS {
    markdownRemark(
      frontmatter: { templateKey: { eq: "all-niseko-pass-page-zhhans" } }
    ) {
      frontmatter {
        title
        subTitle
        heroImageObject {
          image
          overlay
        }
        sections {
          tilesObject {
            linkObject {
              isExternal
              link
              title
            }
            title
            tiles {
              image
              title
              text
            }
          }
          infoTiles {
            image
            title
            text
            linkObject {
              isExternal
              link
              title
            }
          }
        }
        seoObject {
          metaTitle
          metaDescription
        }
        content
      }
    }
  }
`;
